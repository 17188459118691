<script setup lang="ts">
    import Task from '~~/app/task/models/Task';

    const props = defineProps({
  task: null,
  displayIcons: { type: Boolean, default: true }
});

    const { onContextMenu } = useContextMenu({
        eventName: 'task:task:context-menu:open',
        payload: {
            model: props.task,
        },
    });

    const usersSelect = ref(null);
    const dateSelect = ref(null);

    const fixZIndex = computed(() => usersSelect.value?.isDisplayed || dateSelect.value?.isDisplayed);

    // Add handler for td elements
    const handleCellContextMenu = (event: MouseEvent) => {
        // Stop event from bubbling to parent elements
        event.stopPropagation();
        onContextMenu(event);
    };
</script>

<template>
    <tr :class="{ 'relative z-[10]': fixZIndex }">
        <td
            class="px-2 py-2 border-b border-gray-100"
            @contextmenu="handleCellContextMenu"
        >
            <TaskTaskItemCheck :task="task" />
        </td>
        <td
            class="w-full px-2 py-2 border-b border-gray-100"
            @contextmenu="handleCellContextMenu"
        >
            <TaskTaskItemTitle :task="task" />
            <slot
                name="subtitle"
                :task="task"
            >
                <template v-if="task.worksite">
                    <div class="text-xs text-gray-600">
                        <i class="mr-2 fa-regular fa-hard-hat" />
                        <span>
                            {{ task.worksite?.title }}
                        </span>
                    </div>
                </template>
                <template v-if="task.contact">
                    <div class="text-xs text-gray-600">
                        <i class="mr-2 fa-regular fa-user" />
                        <span>
                            {{ task.contact?.displayName }}
                        </span>
                    </div>
                </template>
                <template v-if="task.lead">
                    <div class="text-xs text-gray-600">
                        <i class="mr-2 fa-regular fa-bullseye-arrow" />
                        <span>
                            {{ task.lead?.title }}
                        </span>
                    </div>
                </template>
            </slot>
        </td>
        <td class="px-2 py-2 border-b border-gray-100">
            <TaskTaskItemUsers
                ref="usersSelect"
                :task="task"
            />
        </td>
        <td
            v-if="displayIcons"
            class="px-2 py-2 border-b border-gray-100"
        >
            <span
                class="flex items-center justify-end px-2 space-x-2 text-sm text-gray-600 transition-all cursor-pointer hover:text-black"
                @click.prevent="task.preview()"
            >
                <span v-if="task.commentsCount">
                    {{ task.commentsCount.toShortTimeString() }}
                </span>
                <i class="fa-regular fa-comments" />
            </span>
        </td>
        <td
            v-if="displayIcons"
            class="px-2 py-2 border-b border-gray-100"
        >
            <span
                class="flex items-center justify-end px-2 space-x-2 text-sm text-gray-600 transition-all cursor-pointer hover:text-black"
                @click.prevent="task.preview()"
            >
                <span v-if="task.timeActivitiesTotalDuration">
                    {{ task.timeActivitiesTotalDuration.toShortTimeString() }}
                </span>
                <i class="fa-regular fa-stopwatch" />
            </span>
        </td>
        <td class="px-2 py-2 border-b border-gray-100 whitespace-nowrap">
            <TaskTaskItemDueDate
                ref="dateSelect"
                :task="task"
            />
        </td>
    </tr>
</template>
